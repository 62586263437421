@charset "utf-8";
@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
body {
    padding: 0;
    margin: 0;
    font-family: 'Pretendard';
    font-weight: 300;
    font-size: 16px;
}
/* body{ padding: 0; margin: 0; font-family: 'Noto Sans Kr', sans-serif; font-weight: 300; font-size: 16px;} */

a {
    text-decoration: none !important ;
    color: inherit;
}
a:hover {
    text-decoration: none !important;
    color: inherit;
}

/*마진패딩*/
.mb {
    margin-bottom: 20px !important;
}
.mb5 {
    margin-bottom: 50px !important;
}
.mt {
    margin-top: 20px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mt70 {
    margin-top: 70px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mt90 {
    margin-top: 90px !important;
}
.mt100 {
    margin-top: 100px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}

/* padding */
.pt5 {
    padding-top: 5px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt30 {
    padding-top: 30px !important;
}
.pt40 {
    padding-top: 40px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}
.pb10 {
    padding-bottom: 10px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pb40 {
    padding-bottom: 40px !important;
}
.pl5 {
    padding-left: 5px !important;
}

/* etc */
.mEnter {
    display: block;
}
.block {
    display: block !important;
}
.inblock {
    display: inline-block !important;
}

.fw300 {
    font-weight: 300 !important;
}
.fw400 {
    font-weight: 400 !important;
}
.fw500 {
    font-weight: 500 !important;
}
.fw600 {
    font-weight: 600 !important;
}
.fw700 {
    font-weight: 700 !important;
}
.fw800 {
    font-weight: 800 !important;
}
.fw900 {
    font-weight: 900 !important;
}

.cursorPointer {
    cursor: pointer;
}

.header-menu-right {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 40%);
    transition: 0.5s ease all;
}
.header-menu-right ul {
    padding: 0 5px;
}
.header-menu-right ul li {
    color: #000;
    border-bottom: 1px dashed #e9e9e9;
    display: block;
    padding: 10px 20px;
    font-weight: 500;
}
.header-menu-right ul li:last-child {
    background-color: #fff;
    border-radius: 0;
}
.header-menu-right ul li.active a {
    font-weight: 700;
    color: #4a4d55 !important;
}

.newsList-custom {
    direction: column;
    align-items: center;
    padding: 60px 95px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.newsList-custom .newsList-custom-box {
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1440px;
    display: flex;
}
.newsList-custom .newsList-custom-box .newsList-custom-item {
    width: 100%;
    max-width: 33.3333%;
    padding-bottom: 80px;
    /* padding-inline: 20px; */
    flex-direction: column;
    transition: 0.1s ease-in;
}
.newsList-custom .newsList-custom-box .newsList-custom-item:hover {
    /* transform: translate(0px, -10px); */
    box-shadow: #000;
}
.newsList-custom .newsList-custom-box .newsList-custom-item img {
    height: auto;
    min-height: 250px;
    max-height: 250px;
    object-fit: cover;
    border-radius: 10px;
}
.newsList-custom .newsList-custom-box .newsList-custom-item .text-box {
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 401px;
}
.newsList-custom .newsList-custom-box .newsList-custom-item .text-box p {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    margin-bottom: 0.5em;
    /*     
                      textStyle="md"
                  fontSize={['18px']}
                  fontWeight="bold"
                  w="100%"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  wordBreak="break-all"
                  display="-webkit-box"
                  mb="0.5em"
                  sx={{
                    ['WebkitBoxOrient' as any]: 'vertical',
                    ['WebkitLineClamp' as any]: ['2', '2', '1'],
                  }} */
}
.newsList-custom .newsList-custom-box .newsList-custom-item .text-box span {
    color: #757983;
}

.main-slider-img-web {
    display: flex !important;
}

.main-slider-img-mobile {
    display: none !important;
}

.header {
    height: 68px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
}

.dots_custom {
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
    padding: 0;
}

.dots_custom li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
}

.dots_custom li button {
    border: none;
    background: #b8bcc8;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    padding: 0;
}

.dots_custom li.slick-active button {
    background-color: #ffffff;
}

.footer_image {
    height: 68px;
}

@media all and (max-width: 1440px) {
    .newsList-custom {
        padding: 60px 20px;
    }
    /* .newsList-custom .newsList-custom-box .newsList-custom-item {
        padding-inline: 20px;
    } */
    /* .newsList-custom .newsList-custom-box .newsList-custom-item img {
        min-height: 180px;
        max-height: 180px;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item .text-box {
        max-width: 289px;
    } */
    .newsList-custom .newsList-custom-box .newsList-custom-item {
        max-width: 45%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        text-align: center;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item img {
        min-height: 180px;
        max-height: 180px;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item .text-box {
        max-width: 289px;
        text-align: left;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item {
        display: flex;
        align-items: center;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item .text-box p {
        text-align: left;
    }
}
@media all and (max-width: 1024px) {
    .newsList-custom {
        padding: 40px 20px;
    }
    /* .newsList-custom .newsList-custom-box .newsList-custom-item {
        padding-inline: 20px;
    } */

    .main-slider-img-web {
        display: none !important;
    }

    .main-slider-img-mobile {
        display: flex !important;
    }
    .header {
        height: 60px;
    }
}
@media all and (max-width: 820px) {
    .footer_image {
        height: 45px;
    }
}
@media all and (max-width: 640px) {
    .newsList-custom {
        padding: 35px 30px 0px;
    }
    .newsList-custom .newsList-custom-box {
        justify-content: center;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item {
        max-width: 350px;
        /* padding-inline: 0px; */
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item img {
        min-height: 180px;
        max-height: 180px;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item .text-box {
        max-width: 289px;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item {
        display: flex;
        align-items: center;
    }
    .newsList-custom .newsList-custom-box .newsList-custom-item .text-box p {
        text-align: left;
    }
    .footer_image {
        height: auto;
    }
}

.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li {
    flex: 0 0 auto;
}

.vision-content-list {
    padding-right: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media all and (max-width: 768px) {
    .vision-content-list {
        padding-right: 0px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
