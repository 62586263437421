.wrap {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

/* header */
/* .wrap .header { position: fixed; top: 0; height: 70px; width: 100%; background-color: #fff; z-index: 9990; } */
.wrap .header .header-nav {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wrap .header .news {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
}

.wrap .header .header-nav .header-logo {
    align-items: center;
    width: 20%;
    padding-left: 15px;
    /* padding: 15px 0px 0; */
}
.wrap .header .header-nav .header-logo img {
    width: 100px;
}
.wrap .header .header-nav .header-menu {
    width: 80%;
    padding: 25px 0;
}
.wrap .header .header-nav .header-menu ul {
    padding: 0;
    margin: 0;
    font-size: 0;
}
.wrap .header .header-nav .header-menu ul li {
    display: inline-block;
    width: 143px;
    text-align: right;
    font-size: 15px;
    color: #8c919f;
    font-weight: 500;
    position: relative;
}
.wrap .header .header-submenu1 {
    position: absolute;
    left: 66px;
    top: 60px;
}
.wrap .header .header-submenu1 .header-submenu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    color: #8c919f;
    font-weight: 500;
    height: 44px;
    width: 112px;
    justify-content: center;
}
.wrap .header .header-submenu2 {
    position: absolute;
    left: 54px;
    top: 60px;
}
.wrap .header .header-submenu2 .header-submenu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    color: #8c919f;
    font-weight: 500;
    height: 44px;
    width: 112px;
    justify-content: center;
}
.wrap .header .header-nav .header-menu ul li:active {
    font-weight: 700;
}
.wrap .header .header-nav .header-menu ul li:hover {
    font-weight: 700;
    color: #4a4d55;
}
.wrap .header .header-nav .header-menu ul li a {
    color: #8c919f;
}
.wrap .header .header-nav .header-menu ul li a:hover {
    color: #4a4d55;
}
.wrap .header .header-nav .header-menu ul li.active a {
    font-weight: 700;
    color: #4a4d55 !important;
}

.menu_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    border: 0px;
    display: none;
    z-index: 9999;
    background-color: #fff;
}
.menu_icon:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f0c9';
    color: #4a4d55;
}
.menu_icon.active:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f00d';
    color: #4a4d55;
}
/* header */

/* main-content */
/* .wrap .main { max-width: 1920px; margin: 0 auto 200px; padding-top: 70px; overflow: hidden; } */
.wrap .main {
    margin: 0 auto 200px;
    overflow: hidden;
}
.wrap .main .main-visual {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-visual img {
    width: 100%;
}
/* vision */
.wrap .main .main-content .section01 {
    margin-top: 200px;
}
.wrap .main .main-content .section01 .section-title {
    margin-bottom: 50px;
}
.wrap .main .main-content .section01 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section01 .section-title .title-text p {
    width: 200px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section01 .section-content {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    display: flex;
}
.wrap .main .main-content .section01 .section-content .boxs {
    max-width: 330px;
    border: 1px solid #eaecf0;
    text-align: center;
    padding: 33px 10px;
    color: #2c519a;
    margin-right: 40px;
}
.wrap .main .main-content .section01 .section-content .boxs:last-child {
    margin-right: 0px;
}
.wrap .main .main-content .section01 .section-content .boxs .boxs-title {
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 30px;
}
.wrap .main .main-content .section01 .section-content .boxs .boxs-img {
    margin-bottom: 15px;
}
.wrap .main .main-content .section01 .section-content .boxs .boxs-img img {
    max-width: 140px;
    max-height: 128px;
}
.wrap .main .main-content .section01 .section-content .boxs .boxs-cont {
    padding: 0 25px;
}
.wrap .main .main-content .section01 .section-content .boxs .boxs-cont p {
    font-weight: 600;
    font-size: 20px;
}
/* history */
.wrap .main .main-content .section02 {
    margin-top: 200px;
}
.wrap .main .main-content .section02 .section-title {
    margin-bottom: 80px;
}
.wrap .main .main-content .section02 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section02 .section-title .title-text p {
    width: 260px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section02 .section-content {
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-content .section02 .section-content .history {
    display: flex;
}
.wrap .main .main-content .section02 .section-content .history .year {
    width: 20%;
    padding: 10px 0;
}
.wrap .main .main-content .section02 .section-content .history .year ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.wrap .main .main-content .section02 .section-content .history .year ul div {
    margin-bottom: 80px;
    font-size: 30px;
    font-weight: 600;
    color: #b8bcc8;
}
.wrap .main .main-content .section02 .section-content .history .year ul li:hover,
.wrap .main .main-content .section02 .section-content .history .year ul li.active {
    color: #000 !important;
}
.wrap .main .main-content .section02 .section-content .history .box {
    width: 80%;
    margin: 0 10%;
    overflow: hidden;
    padding: 0px 0px 0px 60px;
}
.wrap .main .main-content .section02 .section-content .history .box ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all 0.5s linear;
    top: 0;
}
/*.wrap .main .main-content .section02 .section-content .history .box ul:last-of-type{ top:80px; }*/
/* .wrap .main .main-content .section02 .section-content .history .box ul:before{ content: ""; display: block; height: 100%; position: absolute; top:0; left:30px; width: 2px; background: #e8e8f6;} */
.wrap .main .main-content .section02 .section-content .history .box ul li {
    margin: 20px 60px 60px;
    position: relative;
    padding: 0px 0px;
    background: rgba(255, 255, 255, 0.3);
    color: #000;
    border-radius: 10px;
    line-height: 30px;
    width: 100%;
}
.wrap .main .main-content .section02 .section-content .history .box ul li > span {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: -30px;
}
.wrap .main .main-content .section02 .section-content .history .box ul li > span:before,
.wrap .main .main-content .section02 .section-content .history .box ul li > span:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: -7.5px;
}
.wrap .main .main-content .section02 .section-content .history .box ul li > span:before {
    top: -10px;
}
.wrap .main .main-content .section02 .section-content .history .box ul li > span:after {
    top: 95%;
}
.wrap .main .main-content .section02 .section-content .history .box ul li > span img {
    margin: 0 0 0 -12.5px;
}
.wrap .main .main-content .section02 .section-content .history .box ul li:last-child > span img {
    margin-top: 3px;
}
.wrap .main .main-content .section02 .section-content .history .box .first-list li span img {
    margin: -1px 0 0 -12.5px;
}
.wrap .main .main-content .section02 .section-content .history .box .first-list li:last-child span img {
    margin-top: 2px;
}
.wrap .main .main-content .section02 .section-content .history .box .info {
    font-size: 18px;
}
.wrap .main .main-content .section02 .section-content .history .box .info:first-letter {
    text-transform: capitalize;
    line-height: 1.7;
}
.wrap .main .main-content .section02 .section-content .history .box .time span {
    position: absolute;
    left: -120px;
    color: #000;
    font-size: 24px;
    font-weight: 500;
}
.wrap .main .main-content .section02 .section-content .history .box .time span:first-child {
    top: 0px;
}
/* investors */
.wrap .main .main-content .section03 {
    margin-top: 200px;
}
.wrap .main .main-content .section03 .section-title {
    margin-bottom: 80px;
}
.wrap .main .main-content .section03 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section03 .section-title .title-text p {
    width: 340px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section03 .section-content {
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-content .section03 .section-content ul {
    padding: 0;
    margin: 0;
    font-size: 0;
}
.wrap .main .main-content .section03 .section-content ul li {
    display: inline-block;
    width: 25%;
    margin-bottom: 50px;
}
.wrap .main .main-content .section03 .section-content ul li img {
    width: 100%;
}
/* partners */
.wrap .main .main-content .section04 {
    margin-top: 200px;
}
.wrap .main .main-content .section04 .section-title {
    margin-bottom: 80px;
}
.wrap .main .main-content .section04 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section04 .section-title .title-text p {
    width: 340px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section04 .section-content {
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-content .section04 .section-content ul {
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: center;
}
.wrap .main .main-content .section04 .section-content ul li {
    display: inline-block;
    width: 25%;
    margin-bottom: 50px;
    text-align: center;
}
.wrap .main .main-content .section04 .section-content ul li img {
    max-height: 80px;
    height: auto;
    max-width: 100%;
}
/* news */
.wrap .main .main-content .section05 {
    margin-top: 200px;
}
.wrap .main .main-content .section05 .section-title {
    margin-bottom: 80px;
}
.wrap .main .main-content .section05 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section05 .section-title .title-text p {
    width: 180px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section05 .section-content {
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul {
    padding: 0;
    margin: 0;
    font-size: 0;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li {
    display: inline;
    font-size: 20px;
    font-weight: 400;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 40px 0;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li:first-child .bbs-list-li {
    padding-top: 0px;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li .bbs-list-li-cont .list-cont-info {
    font-size: 16px;
    font-weight: 400;
}
.wrap
    .main
    .main-content
    .section05
    .section-content
    .bbs-list
    ul
    li
    .bbs-list-li
    .bbs-list-li-cont
    .list-cont-info
    .text-writer {
    padding-right: 15px;
}
.wrap
    .main
    .main-content
    .section05
    .section-content
    .bbs-list
    ul
    li
    .bbs-list-li
    .bbs-list-li-cont
    .list-cont-info
    .text-date {
    color: #b8bcc8;
    font-size: 14px;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li .bbs-list-li-icon {
    width: 35px;
    background-image: url(../images/main/icon-arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li .bbs-list-li-icon:hover {
    width: 35px;
    background-image: url(../images/main/icon-arrow-over.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-last-li {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding-top: 40px;
    position: relative;
}
.wrap
    .main
    .main-content
    .section05
    .section-content
    .bbs-list
    ul
    li
    .bbs-list-last-li
    .bbs-list-li-cont
    .list-cont-info {
    font-size: 16px;
    font-weight: 400;
}
.wrap
    .main
    .main-content
    .section05
    .section-content
    .bbs-list
    ul
    li
    .bbs-list-last-li
    .bbs-list-li-cont
    .list-cont-info
    .text-writer {
    padding-right: 15px;
}
.wrap
    .main
    .main-content
    .section05
    .section-content
    .bbs-list
    ul
    li
    .bbs-list-last-li
    .bbs-list-li-cont
    .list-cont-info
    .text-date {
    color: #b8bcc8;
    font-size: 14px;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-last-li .bbs-list-li-icon {
    width: 35px;
    background-image: url(../images/main/icon-arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-last-li .bbs-list-li-icon:hover {
    width: 35px;
    background-image: url(../images/main/icon-arrow-over.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.wrap .main .main-content .section05 .section-content .bbs-btns {
    width: 100%;
    margin-top: 80px;
    text-align: center;
}
.wrap .main .main-content .section05 .section-content .bbs-btns .bbs-btn {
    border: 1px solid #8c919f;
    color: #282828;
    padding: 10px 30px;
}
.wrap .main .main-content .section05 .section-content .bbs-btns .bbs-btn:hover {
    border: 1px solid #b6c7ff;
    background-color: #b6c7ff;
    color: #fff;
    padding: 10px 30px;
}
/* ci·bi */
.wrap .main .main-content .section06 {
    margin-top: 200px;
}
.wrap .main .main-content .section06 .section-title {
    margin-bottom: 80px;
}
.wrap .main .main-content .section06 .section-title .title-text {
    font-weight: 700;
    font-size: 60px;
}
.wrap .main .main-content .section06 .section-title .title-text p {
    width: 150px;
    text-align: center;
    margin: 0 auto;
}
.wrap .main .main-content .section06 .section-content {
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
}
.wrap .main .main-content .section06 .section-content .section-content-cont {
    line-height: 160%;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs {
    display: flex;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs {
    width: 45%;
    background-color: #fbfcff;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    padding: 15px;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs-blank {
    width: 10%;
}
/*.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs:first-child { margin-right: 140px; }*/
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-title {
    color: #757983;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-logo {
    padding: 30px 0;
    text-align: center;
    max-height: 50px;
    height: auto;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-icon {
    color: #2c519a;
    font-weight: 600;
}
.wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-icon img {
    margin-right: 5px;
}
.wrap .main .main-content .section06 .section-content .section-content-cont-bottom {
    font-size: 15px;
}
/* main-content */

/* sub-content */
/* ------------------------------------- 채용 */
.wrap .sub .sub-title {
    max-width: 1440px;
    margin: 0 auto;
    font-size: 60px;
    font-weight: 600;
    text-align: left;
    padding: 70px 0 40px 0px;
}
/* .wrap .sub-list {
    max-width: 1440px;
    margin: 0 auto;
    font-size: 60px;
    font-weight: 600;
    text-align: left;
} */
.wrap .sub .sub-visual {
    height: 400px;
    position: relative;
}
.wrap .sub .sub-visual img {
    width: 100%;
}
.wrap .sub .sub-visual .sub-visual-text {
    font-size: 45px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 60px;
    transform: translate(0, -50%);
}
/* 공고 */
.wrap .sub .sub-content.recruit01 {
    max-width: 1420px;
    width: 100%;
    margin: 100px auto;
}
.wrap .sub .sub-content.recruit01 .sub-content-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #8c8a8a;
}
.wrap .sub .sub-content.recruit01 .sub-content-menu ul li {
    display: inline-block;
    padding: 10px 40px;
    font-size: 30px;
    color: #8c8a8a;
}
.wrap .sub .sub-content.recruit01 .sub-content-menu ul li:first-child {
    padding-left: 0px;
}
.wrap .sub .sub-content.recruit01 .sub-content-menu ul li.active span {
    color: #000;
    border-bottom: 5px solid;
    padding-bottom: 8px;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont {
    padding: 150px 170px;
    margin: 0 auto;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs {
    margin: 0px;
    padding: 0px;
    font-size: 0px;
    list-style: none;
    border-top: 1px solid #8c8a8a;
    border-bottom: 1px solid #8c8a8a;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li {
    background: none;
    color: #000;
    display: inline-block;
    padding: 15px 0px;
    cursor: pointer;
    width: calc(100% / 6);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li.current {
    color: #fff;
    background-color: #000;
}
.wrap .sub .sub-content.recruit01 .tab-content {
    display: none;
}
.wrap .sub .sub-content.recruit01 .tab-content.current {
    display: inherit;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list-mobile {
    display: none;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li {
    display: block;
    font-size: 18px;
    cursor: pointer;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li:hover ul li {
    font-weight: 600 !important;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-header ul {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-header ul li {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    text-align: left;
    padding: 18px 0;
    border-bottom: 1px solid #d9d9d9;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-content a:hover {
    color: #000;
    font-weight: 600 !important;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-content ul {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-content ul li {
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-align: left;
    padding: 18px 0;
    border-bottom: 1px solid #d9d9d9;
}
.wrap .sub .sub-content.recruit01 .tab-content .bbs-list .bbs-list-ul-header {
}
/* total-cnt */
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-cnt {
    margin-bottom: 15px;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-cnt span {
    font-weight: 600;
}
/* paging */
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging {
    margin-top: 20px;
    text-align: center;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging ul {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging ul li {
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    color: #8c919f;
}
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging ul li:hover,
.wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging ul li.active {
    color: #000;
    font-weight: 600;
}
/* 공고 */
/* 절차 */
.wrap .sub .sub-content.recruit02 {
    max-width: 1420px;
    width: 100%;
    margin: 100px auto;
}
.wrap .sub .sub-content.recruit02 .sub-content-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #8c8a8a;
}
.wrap .sub .sub-content.recruit02 .sub-content-menu ul li {
    display: inline-block;
    padding: 10px 40px;
    font-size: 30px;
    color: #8c8a8a;
}
.wrap .sub .sub-content.recruit02 .sub-content-menu ul li:first-child {
    padding-left: 0px;
}
.wrap .sub .sub-content.recruit02 .sub-content-menu ul li.active span {
    color: #000;
    border-bottom: 5px solid;
    padding-bottom: 8px;
}
.wrap .sub .sub-content.recruit02 .web {
    display: block;
}
.wrap .sub .sub-content.recruit02 .mobile {
    display: none;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont {
    padding: 200px 170px;
    margin: 0 auto;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
    font-size: 36px;
    color: #0d0d0d;
    font-weight: 600;
    text-align: center;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text span {
    display: block;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step {
    justify-content: center;
    display: flex;
    margin-top: 80px;
}
/*.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-img { margin: 0 40px; }*/
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-img img {
    max-width: 100%;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow {
    padding: 0 35px;
    align-items: center;
    display: flex;
}
.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow img {
    max-width: 100%;
}
/*.wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow img { position: absolute; top:50%; transform: translateY(-50%); }*/
/* 절차 */
/* QnA */
.wrap .sub .sub-content.recruit03 {
    max-width: 1420px;
    width: 100%;
    margin: 100px auto;
}
.wrap .sub .sub-content.recruit03 .sub-content-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #8c8a8a;
}
.wrap .sub .sub-content.recruit03 .sub-content-menu ul li {
    display: inline-block;
    padding: 10px 40px;
    font-size: 30px;
    color: #8c8a8a;
}
.wrap .sub .sub-content.recruit03 .sub-content-menu ul li:first-child {
    padding-left: 0px;
}
.wrap .sub .sub-content.recruit03 .sub-content-menu ul li.active span {
    color: #000;
    border-bottom: 5px solid;
    padding-bottom: 8px;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont {
    padding: 150px 170px;
    margin: 0 auto;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna {
    margin: 1em 0;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .qnaHeader {
    position: relative;
    zoom: 1;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .qnaHeader .showAll {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    padding: 0;
    overflow: visible;
    background: none;
    cursor: pointer;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .qnaBody {
    margin: 0;
    padding: 0;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .qnaBody .article {
    list-style: none;
    border-bottom: 1px solid #bdbdbd;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q {
    margin: 30px 10px;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a {
    display: block;
    text-align: left;
    font-size: 20px;
    background: url('../images/sub/Q.png') no-repeat 0 0;
    padding: 0 0 0 35px;
    color: #5e5e5e;
    font-weight: bold;
    line-height: 27px;
    cursor: pointer;
    margin: 10px 0 !important;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title {
    display: flex;
    justify-content: space-between;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title:hover {
    color: #000;
    font-weight: 600;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title .q-arrow {
    width: 30px;
    float: right;
    padding-right: 10px;
    background: url(../images/sub/qna-up.png) no-repeat center;
    background-size: auto;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title .q-arrow.active {
    width: 30px;
    float: right;
    padding-right: 10px;
    background: url(../images/sub/qna-down.png) no-repeat center;
    background-size: auto;
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a:hover,
.qna .q a:active,
.qna .q a:focus {
}
.wrap .sub .sub-content.recruit03 .sub-content-cont .qna .a {
    padding: 40px 70px;
    font-size: 15px;
    color: #444444;
    line-height: 22px;
    margin: 5px 0 0 0;
}

.wrap .sub .sub-content .bbs-btns {
    width: 100%;
    margin-top: 80px;
    text-align: center;
}
.wrap .sub .sub-content .bbs-btns .bbs-btn {
    border: 1px solid #8c919f;
    color: #282828;
    padding: 10px 30px;
}
.wrap .sub .sub-content .bbs-btns .bbs-btn:hover {
    border: 1px solid #b6c7ff;
    background-color: #b6c7ff;
    color: #fff;
    padding: 10px 30px;
}
/* QnA */
/* ------------------------------------- 채용 */

/* ------------------------------------- 복지 */
.wrap .sub .sub-content.welfare {
    max-width: 1020px;
    width: 100%;
    margin: 0px auto;
}
.wrap .sub .sub-content.welfare .sub-content-cont .web {
    display: block;
}
.wrap .sub .sub-content.welfare .sub-content-cont .mobile {
    display: none;
}
.wrap .sub .sub-content.welfare .sub-content-cont {
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li {
    display: inline-block;
    width: calc(50% - 10px);
    margin-bottom: 100px;
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont {
    display: inline-flex;
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img {
    position: relative;
    width: 160px;
    height: 160px;
    background-image: url('../images/sub/welfare-icon-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img .img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img img {
    max-height: 130px;
    max-width: 100px;
}
.wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-text {
    font-size: 16px;
    padding-left: 20px;
}
.wrap
    .sub
    .sub-content.welfare
    .sub-content-cont
    .sub-content-cont-welfare
    li
    div.welfare-cont
    .welfare-cont-text
    span.welfare-cont-text-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
    display: block;
    margin-bottom: 20px;
}
.wrap
    .sub
    .sub-content.welfare
    .sub-content-cont
    .sub-content-cont-welfare
    li
    div.welfare-cont
    .welfare-cont-text
    span.welfare-cont-text-subtitle {
    font-size: 18px;
    letter-spacing: -1px;
    display: block;
    padding-bottom: 20px;
}
.wrap
    .sub
    .sub-content.welfare
    .sub-content-cont
    .sub-content-cont-welfare
    li
    div.welfare-cont
    .welfare-cont-text
    .welfare-cont-text-cont {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
}
.wrap
    .sub
    .sub-content.welfare
    .sub-content-cont
    .sub-content-cont-welfare
    li
    div.welfare-cont
    .welfare-cont-text
    .welfare-cont-text-cont
    li {
    display: table-row;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 170%;
}
.wrap
    .sub
    .sub-content.welfare
    .sub-content-cont
    .sub-content-cont-welfare
    li
    div.welfare-cont
    .welfare-cont-text
    .welfare-cont-text-cont
    li
    span.welfare-cont-text-dot {
    font-weight: 600;
    margin: 0 5px;
}
/* ------------------------------------- 복지 */

/* ------------------------------------- 보도자료 */
.wrap .sub .sub-content.bbs {
    width: 100%;
    margin: 10px auto;
    padding-bottom: 50px;
}
.wrap .sub .sub-content.bbs .sub-content-cont {
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul {
    overflow: hidden;
    width: 100%;
    padding: 50px 20px;
    font-size: 0;
    list-style: none;
    margin: 0;
    box-sizing: border-box;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li {
    position: relative;
    width: calc(33.3% - 26.666px);
    margin-left: 40px;
    float: left;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px #b8bcc8;
    border-radius: 20px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:hover {
    transition: all 0.3s ease;
    transform: translateY(-5px);
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li.no_shadow {
    box-shadow: none;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(3n + 1) {
    margin-left: 0;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(n + 4) {
    margin-top: 50px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .boxs .thumb {
    overflow: hidden;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .boxs .thumb img {
    max-width: 100%;
    width: 100%;
    height: 290px;
    margin: 0 auto;
    display: block;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents {
    position: relative;
    padding: 15px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .date {
    font-size: 15px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents button {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 1;
    padding: 0;
    border: 0;
    background-color: transparent;
    float: right;
    cursor: pointer;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents button img {
    width: 40px;
    display: block;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
    line-height: 160%;
    font-size: 18px;
    letter-spacing: -0.03em;
    font-weight: 500;
    padding-top: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: none;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option.on {
    display: flex;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option .wrap {
    padding: 20px;
    justify-content: center;
    align-items: flex-end;
    flex-flow: column;
    display: flex;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option .wrap button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 25px;
    cursor: pointer;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option .wrap button:first-child {
    margin-top: 0;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .option .wrap button img {
    display: block;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-paging {
    margin: 20px 0;
    text-align: center;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-paging ul li {
    display: inline-block;
    padding: 5px 10px;
    color: #8c919f;
    font-size: 16px;
}
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-paging ul li:hover,
.wrap .sub .sub-content.bbs .sub-content-cont .bbs-paging ul li.active {
    color: #000;
    font-weight: 600;
    color: #000;
    font-weight: 600;
}

.wrap .sub .sub-content.recruit01 .tab-content .bbs-list-mobile {
    padding-left: 24px;
    padding-right: 24px;
}
.bbs-list-mobile .bbs-list-ul-content-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}
.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile1 {
    display: flex;
    flex-direction: column;
    width: 65%;
}
.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile1 .bbs-list-careerTypeName-mobile {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #757983;
}
.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile1 .bbs-list-jobCategoryName-mobile {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #131313;
    margin-top: 5px;
}

.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile2 {
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
}
.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile2 .bbs-list-postingEndDate-mobile {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.01em;
    color: #757983;
}
.bbs-list-mobile .bbs-list-ul-content-mobile .bbs-list-ul-content-mobile2 .bbs-list-statusName-mobile {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    min-width: 78px;
    height: 25px;
    background: #131313;
    text-align: center;
    border-radius: 30px;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
}

.wrap .sub .sub-content.bbs-view {
    max-width: 1080px;
    width: 100%;
    margin: 10px auto;
    padding-bottom: 100px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont {
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header {
    width: 100%;
    border-bottom: 2px solid #dadce3;
    padding: 0 5px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .title {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .date {
    color: #8c919f;
    font-size: 16px;
    margin: 5px 0;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns {
    display: flex;
    justify-content: space-between;
    color: #8c919f;
    margin: 20px 0;
    height: 40px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-call {
    display: table;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-call span {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-call span img {
    vertical-align: bottom;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-call span.call-number {
    letter-spacing: 2px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc {
    border-radius: 50%;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc span {
    padding-left: 10px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc span img {
    height: -webkit-fill-available;
}
/*.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc span.link_copy,*/
/*.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc span.sns-layer-popup { cursor: pointer; }*/
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc {
    display: flex;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc div {
    width: 38px;
    height: 38px;
    cursor: pointer;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc .link_copy {
    background-image: url('../images/common/link-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc .link_copy:hover {
    background-image: url('../images/common/link-icon-hover.svg');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc .sns-layer-popup {
    background-image: url('../images/common/share-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .btns .btn-etc .sns-layer-popup:hover {
    background-image: url('../images/common/share-icon-hover.svg');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont {
    padding: 40px;
    margin: 0 auto;
    text-align: center;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-img {
    padding: 10px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-img img {
    width: 100%;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-text {
    padding: 10px;
    text-align: left;
    font-size: 15px;
    letter-spacing: -1px;
    line-height: 150%;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next {
    margin: 50px 0;
    color: #757983;
    font-size: 15px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before {
    display: inline-flex;
    border-top: 1px solid #8c919f;
    border-bottom: 1px solid #8c919f;
    width: 100%;
    padding: 10px 0;
    align-items: center;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-icon {
    display: table-cell;
    margin-right: 30px;
    width: 30px;
    height: 30px;
    background-image: url('../images/common/icon-up.png');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before:hover .before-icon {
    background-image: url('../images/sub/iocn-w.svg');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-icon img {
    vertical-align: bottom;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-type {
    margin-right: 30px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before:hover .before-type,
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before:hover .before-text {
    color: #000;
    font-weight: 500;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next {
    display: inline-flex;
    border-bottom: 1px solid #8c919f;
    width: 100%;
    padding: 10px 0;
    align-items: center;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-icon {
    display: table-cell;
    margin-right: 30px;
    width: 30px;
    height: 30px;
    background-image: url('../images/common/icon-down.png');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next:hover .next-icon {
    background-image: url('../images/sub/icon-d.svg');
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-icon img {
    vertical-align: bottom;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-type {
    margin-right: 30px;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next:hover .next-type,
.wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next:hover .next-text {
    color: #000;
    font-weight: 500;
}
.wrap .sub .sub-content.bbs-view .bbs-btns {
    width: 100%;
    margin: 40px 0;
    text-align: center;
}
.wrap .sub .sub-content.bbs-view .bbs-btns .bbs-btn {
    border: 1px solid #8c919f;
    color: #282828;
    padding: 10px 30px;
}
.wrap .sub .sub-content.bbs-view .bbs-btns .bbs-btn:hover {
    border: 1px solid #8c919f;
    background-color: #8c919f;
    color: #fff;
    padding: 10px 30px;
}
/* ------------------------------------- 보도자료 */

/* sub-content */
/* footer */
.wrap .footer {
    background-color: #131313;
    color: #fff;
}
.wrap .footer .footer-wrap {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
}
.wrap .footer .footer-wrap .footer-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
}
.wrap .footer .footer-wrap .footer-header .footer-menu ul {
    padding: 0;
}
.wrap .footer .footer-wrap .footer-header .footer-menu ul li {
    display: inline-block;
    padding: 0 10px;
}
.wrap .footer .footer-wrap .footer-header .footer-menu ul li a {
    color: #fff;
    font-weight: 600;
}
.wrap .footer .footer-wrap .footer-header .footer-sns-icon ul {
    padding: 0;
}
.wrap .footer .footer-wrap .footer-header .footer-sns-icon ul li {
    display: inline-block;
    padding: 0 5px;
}
.wrap .footer .footer-wrap .footer-info {
    padding-top: 50px;
}
.wrap .footer .footer-wrap .footer-info .footer-info-cont {
    display: flex;
    justify-content: space-between;
}
.wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-text {
    align-items: center;
    display: flex;
}
.wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-text span {
    display: block;
    font-size: 14px;
    line-height: 200%;
}
.wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-contact span {
    display: block;
    font-size: 14px;
    line-height: 200%;
}
/* footer */

/* etc */
.text-p1 {
    background: linear-gradient(to right, rgba(190, 25, 45, 1), rgba(45, 82, 155, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* top */
.btn-top {
    display: none;
    position: fixed;
    z-index: 9990;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
}

/* 팝업 기본디자인 */
.layer-popup,
.close {
    cursor: pointer;
}
.popup {
    border: 1px solid #dcdcdc;
    position: fixed;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 30px 20px;
    border-radius: 20px;
    background: #fff;
    z-index: 9999;
}
.popup .close {
    background: none;
    border: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
}
.popup-content {
    display: flex;
    color: #434242;
}
.popup-content01 {
    width: 46%;
    border: 1px solid #dadce3;
    margin-right: 4%;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
}
.popup-content01:hover {
    border: 1px solid #10cfc9;
}
.popup-content02 {
    width: 46%;
    border: 1px solid #dadce3;
    margin-left: 4%;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
}
.popup-content02:hover {
    border: 1px solid #10cfc9;
}
.popup-content01-img {
    display: inline-flex;
}
.popup-content .popup-content-circle {
    background-color: #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 10px 0;
}
.popup-content .popup-content-circle img {
    width: 35px;
    margin-top: 50%;
    transform: translate(0, -50%);
}
.popup-content-circle-title {
    font-size: 18px;
    font-weight: 500;
}
.popup-content-circle-text,
.popup-content-circle-mail {
    font-size: 12px;
}

/* 팝업 - 공유하기 */
.popup.modal2 .popup-content {
    display: flow-root;
}
.popup.modal2 .popup-content .popup-title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 35px;
}
.popup.modal2 .popup-content .popup-ul ul {
    display: flex;
}
.popup.modal2 .popup-content .popup-ul ul li {
    display: inline-block;
    list-style: none;
    width: calc(100% / 3 - 10px);
    height: 65px;
    text-align: center;
    cursor: pointer;
}
.popup.modal2 .popup-content .popup-ul ul li.pop-twitter {
    background-image: url('../images/common/view-twitter-icon.svg');
    background-repeat: no-repeat;
    background-position-x: center;
}
.popup.modal2 .popup-content .popup-ul ul li.pop-twitter:hover {
    background-image: url('../images/common/view-twitter-icon-hover.svg');
}
.popup.modal2 .popup-content .popup-ul ul li.pop-kakao {
    background-image: url('../images/common/view-kakao-icon.svg');
    background-repeat: no-repeat;
    background-position-x: center;
}
.popup.modal2 .popup-content .popup-ul ul li.pop-kakao:hover {
    background-image: url('../images/common/view-kakao-icon-hover.svg');
}
.popup.modal2 .popup-content .popup-ul ul li.pop-facebook {
    background-image: url('../images/common/view-facebook-icon.svg');
    background-repeat: no-repeat;
    background-position-x: center;
}
.popup.modal2 .popup-content .popup-ul ul li.pop-facebook:hover {
    background-image: url('../images/common/view-facebook-icon-hover.svg');
}

/* 쉐도우 효과*/
.shadow {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100vh;
    display: none;
    z-index: 9998;
}

/* 메인 상단 */
.main-video {
    min-height: auto;
}
.main-video .main-videoBox {
    position: relative;
    flex-flow: column;
    display: flex;
    margin: 1% 0.5%;
    left: 0.2%;
}
.main-video .main-videoBox .videoBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-property: transform;
    transition-duration: 0s;
    will-change: transform;
}
.main-video .main-videoBox .main-videoText01 {
    position: relative;
    height: 19%;
    background-color: transparent;
    align-items: center;
    display: flex;
    width: fit-content;
    font-size: 8em;
    font-weight: 900;
    background-color: #fff;
    padding-right: 5%;
}
.main-video .main-videoBox .main-videoText02 {
    position: relative;
    height: 19%;
    background-color: transparent;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: fit-content;
    font-size: 8em;
    font-weight: 900;
    background-color: #fff;
    text-align: left;
    padding-right: 5%;
}
.main-video .main-videoBox .main-videoText03 {
    position: relative;
    height: 19%;
    background-color: transparent;
    align-items: center;
    display: flex;
    width: fit-content;
    font-size: 8em;
    font-weight: 900;
    background-color: #fff;
    padding-right: 3%;
}
.main-video .main-videoBox .main-videoText04 {
    position: relative;
    height: 19%;
    background-color: transparent;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: fit-content;
    font-size: 8em;
    font-weight: 900;
    background-color: #fff;
    text-align: left;
    padding-right: 5%;
}
/* .main-video .main-videoBox .main-videoText01 { position: relative; height: 19%; background-color: transparent; align-items: center; display: flex; }
.main-video .main-videoBox .main-videoText01 .keyText01 { width: fit-content; font-size: 8em; font-weight: 900; background-color: #fff; min-width: 35%; padding-right: 5%; }
.main-video .main-videoBox .main-videoText02 { position: relative; height: 19%; background-color: transparent; justify-content: flex-end; align-items: center; display: flex; }
.main-video .main-videoBox .main-videoText02 .keyText02 { width: fit-content; font-size: 8em; font-weight: 900; background-color: #fff; min-width: 10%; padding-left: 5%; text-align: right; }
.main-video .main-videoBox .main-videoText03 { position: relative; height: 19%; background-color: transparent; align-items: center; display: flex; }
.main-video .main-videoBox .main-videoText03 .keyText03 { width: fit-content; font-size: 8em; font-weight: 900; background-color: #fff;min-width: 25%; padding-right: 5%; }
.main-video .main-videoBox .main-videoText04 { position: relative; height: 19%; background-color: transparent; justify-content: flex-end; align-items: center; display: flex; }
.main-video .main-videoBox .main-videoText04 .keyText04 { width: fit-content; font-size: 8em; font-weight: 900; background-color: #fff; min-width: 35%; padding-left: 5%; text-align: right; } */
.main-video .main-videoBox .main-video-blank {
    background-color: #fff;
    z-index: 1;
    height: 50px;
}

.pressList {
    max-width: 1440px;
}

.pressList .press_title {
    color: #131313;
    font-weight: 700;
    font-size: 60px;
    line-height: 90px;
    letter-spacing: -0.03em;
    margin: 0;
    margin-top: 32px;
}

.pressList .name_press {
    color: #be182c;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.03em;
    margin: 0;
    margin-top: 32px;
}

.pressList .main_new_image_web {
    display: block;
}
.pressList .news_letter_mobile {
    display: none;
}
.pressList .news_letter_web {
    display: block;
}
.pressList .main_new_image_mobile {
    display: none;
}

@media all and (max-width: 1440px) {
    .main-video {
        min-height: auto;
    }

    .wrap .header .header-nav {
        max-width: 1024px;
    }

    .wrap .header .news {
        max-width: 1024px;
    }

    .pressList {
        max-width: 1024px;
    }
    .pressList .main_new_image_web {
        display: none;
    }
    .pressList .news_letter_web {
        display: none;
    }
    .pressList .news_letter_mobile {
        display: block;
    }
    .pressList .news_letter_mobile .news_letter_mobile_box {
        padding-left: 80px;
        padding-right: 80px;
    }
    .pressList .main_new_image_mobile {
        display: block;
        margin-top: 48px;
    }

    .pressList .press_title {
        font-size: 50px;
        line-height: 80px;
    }
    .main-video .main-videoBox .main-videoText01,
    .main-video .main-videoBox .main-videoText02,
    .main-video .main-videoBox .main-videoText03,
    .main-video .main-videoBox .main-videoText04 {
        font-size: 7em;
    }
    /* .main-video .main-videoBox .main-videoText01 .keyText01,
    .main-video .main-videoBox .main-videoText02 .keyText02,
    .main-video .main-videoBox .main-videoText03 .keyText03,
    .main-video .main-videoBox .main-videoText04 .keyText04 { font-size: 7em;} */
}
@media all and (max-width: 1024px) {
    .pressList .press_title {
        font-size: 25px;
        line-height: 35px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .pressList .name_press {
        font-size: 18px;
        line-height: 27px;
        margin-top: 48px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .pressList .news_letter_mobile .news_letter_mobile_box {
        padding-left: 20px;
        padding-right: 20px;
    }
    .main-video {
        min-height: auto;
    }

    .main-video .main-videoBox {
        margin-top: 5%;
    }
    .main-video .main-videoBox .main-videoText01,
    .main-video .main-videoBox .main-videoText02,
    .main-video .main-videoBox .main-videoText03,
    .main-video .main-videoBox .main-videoText04 {
        font-size: 6em;
    }
    .history_icon {
        display: none;
    }
    .wrap .main .main-content .section02 .section-content .history .box .time span {
        left: -80px;
    }
    /* .main-video .main-videoBox { margin-top: 5%; }
    .main-video .main-videoBox .main-videoText01 .keyText01,
    .main-video .main-videoBox .main-videoText02 .keyText02,
    .main-video .main-videoBox .main-videoText03 .keyText03,
    .main-video .main-videoBox .main-videoText04 .keyText04 { font-size: 6em;} */
}
@media all and (max-width: 640px) {
    .wrap .main .main-content .section02 .section-content .history .box .time span {
        left: -110px;
    }
    .main-video {
        min-height: auto;
    }
    .main-video .main-videoBox {
        margin-top: 5%;
    }
    .main-video .main-videoBox .main-videoText01,
    .main-video .main-videoBox .main-videoText02,
    .main-video .main-videoBox .main-videoText03,
    .main-video .main-videoBox .main-videoText04 {
        font-size: 3em;
    }
    .main-video .main-videoBox .main-video-blank {
        height: 20px;
    }
    /* .main-video .main-videoBox { margin-top: 5%; }
    .main-video .main-videoBox .main-videoText01 .keyText01,
    .main-video .main-videoBox .main-videoText02 .keyText02,
    .main-video .main-videoBox .main-videoText03 .keyText03,
    .main-video .main-videoBox .main-videoText04 .keyText04 { font-size: 3em;}
    .main-video .main-videoBox .main-video-blank { height: 20px;} */

    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list-web {
        display: none;
    }
    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list-mobile {
        display: block;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .web {
        display: none;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .mobile {
        display: block;
    }

    .wrap .sub .sub-content.recruit02 .web {
        display: none;
    }
    .wrap .sub .sub-content.recruit02 .mobile {
        display: block;
    }
}
/* 메인 상단 */

/* etc */
@media all and (max-width: 1440px) {
    .wrap .sub .sub-content.recruit01,
    .wrap .sub .sub-content.recruit02,
    .wrap .sub .sub-content.recruit03 {
        padding: 0 10px;
        width: auto;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
}
@media all and (max-width: 1440px) {
    .wrap .sub .sub-content.recruit01,
    .wrap .sub .sub-content.recruit02,
    .wrap .sub .sub-content.recruit03 {
        padding: 0 10px;
        width: auto;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
}
@media all and (max-width: 1280px) {
    /* header */
    /* .wrap .header .header-nav .header-logo {
        padding: 15px 0px 0;
    } */

    /* main */
    .wrap .main .main-content .section01 .section-title .title-text,
    .wrap .main .main-content .section02 .section-title .title-text,
    .wrap .main .main-content .section03 .section-title .title-text,
    .wrap .main .main-content .section04 .section-title .title-text,
    .wrap .main .main-content .section05 .section-title .title-text,
    .wrap .main .main-content .section06 .section-title .title-text {
        font-size: 50px;
    }
    /* main */

    /* sub */
    .wrap .sub .sub-title {
        font-size: 50px;
        padding: 50px 0 30px 0;
    }
    .wrap .sub .sub-visual {
        height: 350px;
    }
    .wrap .sub .sub-visual .sub-visual-text {
        font-size: 40px;
    }

    /* 보도자료 */
    .wrap .sub .sub-content.bbs-view {
        width: auto;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header {
        width: auto;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }

    /* 채용 */
    .wrap .sub .sub-content.recruit01 .sub-content-cont,
    .wrap .sub .sub-content.recruit02 .sub-content-cont,
    .wrap .sub .sub-content.recruit03 .sub-content-cont {
        padding: 100px 120px;
    }
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
        font-size: 32px;
    }
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow {
        padding: 0 25px;
    }
    /* sub */

    /* footer */
    .wrap .footer .footer-wrap {
        width: auto;
        padding: 40px 10px;
        font-size: 14px;
    }
    .wrap .footer .footer-wrap .footer-info {
        padding-top: 50px;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-text span {
        font-size: 13px;
        line-height: 200%;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-contact span {
        font-size: 13px;
        line-height: 200%;
    }
    /* footer */
}
@media all and (max-width: 1024px) {
    /* header */
    /* .wrap .header .header-nav .header-logo {
        padding: 15px 10px 0;
    } */

    .wrap .header-menu {
        position: fixed;
        top: 0;
        right: -300px;
        z-index: 9998;
        width: 300px;
        height: 100%;
        padding-top: 80px;
        background-color: #fff;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
        transition: 0.5s ease all;
    }
    .wrap .header-menu ul {
        padding: 0 5px;
    }
    .wrap .header-menu ul li {
        color: #000;
        border-bottom: 1px dashed #e9e9e9;
        display: block;
        padding: 10px 20px;
    }
    .wrap .header-menu ul li:last-child {
        background-color: #fff;
        border-radius: 0;
    }

    .menu_icon {
        display: block;
    }
    .wrap .header .header-nav .header-menu {
        display: none;
    }
    .wrap .header .news {
        display: none;
    }
    /* header */

    /* main */
    .wrap .main .main-content .section01,
    .wrap .main .main-content .section02,
    .wrap .main .main-content .section03,
    .wrap .main .main-content .section04,
    .wrap .main .main-content .section05,
    .wrap .main .main-content .section06 {
        margin-top: 100px;
        padding: 0 30px;
    }

    .wrap .main .main-content .section01 .section-title .title-text,
    .wrap .main .main-content .section02 .section-title .title-text,
    .wrap .main .main-content .section03 .section-title .title-text,
    .wrap .main .main-content .section04 .section-title .title-text,
    .wrap .main .main-content .section05 .section-title .title-text,
    .wrap .main .main-content .section06 .section-title .title-text {
        font-size: 45px;
    }

    .wrap .main .main-content .section01 .section-content .boxs .boxs-title {
        font-size: 25px;
    }
    .wrap .main .main-content .section01 .section-content .boxs .boxs-cont {
        font-size: 15px;
    }
    .wrap .main .main-content .section02 .section-content .history {
        display: flow-root;
    }
    .wrap .main .main-content .section02 .section-content .history .year,
    .wrap .main .main-content .section02 .section-content .history .box {
        width: 100%;
    }
    .wrap .main .main-content .section02 .section-content .history .box .time span {
        font-size: 22px;
    }
    .wrap .main .main-content .section02 .section-content .history .box .info {
        font-size: 17px;
    }
    .wrap .main .main-content .section02 .section-content .history .year ul div {
        margin-bottom: 40px;
        font-size: 25px;
        font-weight: 600;
        color: #b8bcc8;
        display: inline-flex;
        width: calc(100% / 4 - 3px);
        justify-content: center;
    }
    .wrap .main .main-content .section01 .section-content .boxs .boxs-cont p {
        font-size: 18px;
    }
    .wrap .main .main-content .section03 .section-content ul li {
        width: calc(100% / 3);
    }
    .wrap .main .main-content .section04 .section-content ul li {
        width: calc(100% / 3);
    }
    .wrap .main .main-content .section06 .section-content {
        font-size: 15px;
    }
    .wrap .main .main-content .section06 .section-content .section-content-cont-bottom {
        font-size: 14px;
    }
    /* main */

    /* sub */
    .wrap .sub .sub-title {
        width: auto;
        font-size: 45px;
        padding: 50px 10px 0px 10px;
    }
    .wrap .sub .sub-visual {
        height: 300px;
        margin-top: 30px;
    }
    .wrap .sub .sub-visual .sub-visual-text {
        font-size: 35px;
    }
    /* 보도자료 */
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
    .wrap .sub .sub-content.bbs-view {
        width: auto;
        margin: 10px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .title {
        font-size: 25px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .date {
        font-size: 14px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont {
        padding: 20px;
    }
    /* 복지 */
    .wrap .sub .sub-content.welfare .sub-content-cont {
        padding: 0 10px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img {
        width: 150px;
        height: 150px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-img
        img {
        max-height: 100px;
        max-width: 100px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-text {
        font-size: 16px;
        padding-left: 20px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-title {
        font-size: 20px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-subtitle {
        font-size: 16px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        .welfare-cont-text-cont
        li {
        font-size: 15px;
    }
    /* 채용 */
    .wrap .sub .sub-content.recruit01,
    .wrap .sub .sub-content.recruit02,
    .wrap .sub .sub-content.recruit03 {
        margin-top: 60px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont,
    .wrap .sub .sub-content.recruit02 .sub-content-cont,
    .wrap .sub .sub-content.recruit03 .sub-content-cont {
        padding: 80px 80px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-menu ul li,
    .wrap .sub .sub-content.recruit02 .sub-content-menu ul li,
    .wrap .sub .sub-content.recruit03 .sub-content-menu ul li {
        font-size: 25px;
    }
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow {
        padding: 0 15px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q {
        margin: 20px 10px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a {
        font-size: 18px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title .q-arrow {
        width: 20px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title .q-arrow.active {
        width: 20px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .a {
        padding: 30px 40px;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .a {
        font-size: 14px;
    }

    .wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-cnt {
        font-size: 15px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li {
        font-size: 16px;
    }
    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-header ul li {
        font-size: 16px;
    }
    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-content ul li {
        font-size: 15px;
    }

    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
        font-size: 28px;
    }
    /* sub */

    /* footer */
    .wrap .footer .footer-wrap {
        width: auto;
        padding: 30px 10px;
        font-size: 13px;
    }
    .wrap .footer .footer-wrap .footer-header {
        padding-bottom: 10px;
    }
    .wrap .footer .footer-wrap .footer-info {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-text span {
        font-size: 12px;
        line-height: 200%;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-contact span {
        font-size: 12px;
        line-height: 200%;
    }
    /* footer */

    .btn-top {
        position: fixed;
        z-index: 9999;
        right: 5px;
        bottom: 5px;
        cursor: pointer;
    }
}
@media all and (max-width: 890px) {
    /* sub */
    .wrap .sub .sub-visual .sub-visual-text {
        font-size: 30px;
    }
    /* 복지 */
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li {
        margin-bottom: 80px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img {
        width: 150px;
        height: 150px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-img
        img {
        max-height: 100px;
        max-width: 100px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-text {
        font-size: 16px;
        padding-left: 20px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-title {
        font-size: 18px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-subtitle {
        font-size: 15px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        .welfare-cont-text-cont
        li {
        font-size: 14px;
    }
    /* 채용 */
    .wrap .sub .sub-content.recruit01 .sub-content-cont,
    .wrap .sub .sub-content.recruit02 .sub-content-cont,
    .wrap .sub .sub-content.recruit03 .sub-content-cont {
        padding: 60px 20px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li {
        width: calc(100% / 3);
    }
}
@media all and (max-width: 768px) {
    /* main */
    .wrap .main .main-content .section01 .section-title .title-text,
    .wrap .main .main-content .section02 .section-title .title-text,
    .wrap .main .main-content .section03 .section-title .title-text,
    .wrap .main .main-content .section04 .section-title .title-text,
    .wrap .main .main-content .section05 .section-title .title-text,
    .wrap .main .main-content .section06 .section-title .title-text {
        font-size: 40px;
    }

    .wrap .main .main-content .section01 .section-content {
        display: flow-root;
    }
    .wrap .main .main-content .section01 .section-content .boxs {
        width: auto;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .wrap .main .main-content .section02 .section-content .history .year,
    .wrap .main .main-content .section02 .section-content .history .box {
        margin: 0%;
    }
    .wrap .main .main-content .section02 .section-content .history .box ul li {
        padding: 0 20px;
        margin: 20px 60px 50px;
    }
    .wrap .main .main-content .section02 .section-content .history .box .time span {
        font-size: 20px;
    }
    .wrap .main .main-content .section02 .section-content .history .box .info {
        font-size: 16px;
    }

    .wrap .main .main-content .section06 .section-content .section-content-cont-bottom {
        font-size: 13px;
    }
    .wrap .main .main-content .section06 .section-content .section-content-boxs {
        display: flow-root;
    }
    .wrap .main .main-content .section06 .section-content .section-content-boxs .boxs {
        width: auto;
    }
    .wrap .main .main-content .section06 .section-content .section-content-boxs .boxs:first-child {
        margin-bottom: 10px;
    }
    .wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-logo img {
        width: auto;
    }

    .wrap .main .main-content .section03 .section-content ul li {
        width: calc(100% / 2);
    }
    .wrap .main .main-content .section04 .section-content ul li {
        width: calc(100% / 2 - 25px);
        padding: 0 10px;
    }
    /* main */

    /* sub */
    .wrap .sub .sub-visual {
        height: 300px;
    }
    .wrap .sub .sub-visual .sub-visual-text {
        width: auto;
        font-size: 30px;
        padding: 0 10px;
    }
    /* 보도자료 */
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li {
        width: calc(50% - 10px);
        margin-left: 20px;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(3n + 1) {
        margin-left: 20px;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(2n + 1) {
        margin-left: 0px;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(n + 3) {
        margin-top: 50px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .title {
        font-size: 22px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-icon,
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-icon {
        margin-right: 15px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .before .before-type,
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont .bbs-view-cont-next .next .next-type {
        display: none;
    }
    /* 복지 */
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li {
        width: 100%;
        margin-bottom: 50px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-img {
        width: 140px;
        height: 140px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-img
        img {
        max-height: 100px;
        max-width: 100px;
    }
    .wrap .sub .sub-content.welfare .sub-content-cont .sub-content-cont-welfare li div.welfare-cont .welfare-cont-text {
        font-size: 16px;
        padding-left: 20px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-title {
        font-size: 16px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        span.welfare-cont-text-subtitle {
        font-size: 14px;
    }
    .wrap
        .sub
        .sub-content.welfare
        .sub-content-cont
        .sub-content-cont-welfare
        li
        div.welfare-cont
        .welfare-cont-text
        .welfare-cont-text-cont
        li {
        font-size: 14px;
    }
    /* 채용 */
    .wrap .sub .sub-content.recruit01 .sub-content-menu ul li,
    .wrap .sub .sub-content.recruit02 .sub-content-menu ul li,
    .wrap .sub .sub-content.recruit03 .sub-content-menu ul li {
        font-size: 22px;
        width: 33.3333%;
        padding: 10px 0;
        text-align: center;
    }
    .wrap .sub .sub-content.recruit03 .sub-content-cont .qna .q a .q-title .q-arrow {
        display: none;
    }

    .wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-cnt {
        font-size: 14px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont .tab-content-paging ul li {
        font-size: 14px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs li {
        font-size: 15px;
    }
    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-header ul li {
        font-size: 15px;
    }
    .wrap .sub .sub-content.recruit01 .tab-content .bbs-list ul li.bbs-list-ul-content ul li {
        font-size: 14px;
    }

    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
        font-size: 25px;
    }
}
@media all and (max-width: 640px) {
    /* main */
    .wrap .main .main-content .section01,
    .wrap .main .main-content .section02,
    .wrap .main .main-content .section03,
    .wrap .main .main-content .section04,
    .wrap .main .main-content .section05,
    .wrap .main .main-content .section06 {
        margin-top: 100px;
        padding: 0 20px;
    }

    .wrap .main .main-content .section01 .section-title .title-text,
    .wrap .main .main-content .section02 .section-title .title-text,
    .wrap .main .main-content .section03 .section-title .title-text,
    .wrap .main .main-content .section04 .section-title .title-text,
    .wrap .main .main-content .section05 .section-title .title-text,
    .wrap .main .main-content .section06 .section-title .title-text {
        font-size: 35px;
    }

    .wrap .main .main-content .section02 .section-content .history .box ul li {
        padding: 0px;
        width: 75%;
    }

    .wrap .main .main-content .section05 .section-content .bbs-list ul li {
        font-size: 16px;
    }
    .wrap
        .main
        .main-content
        .section05
        .section-content
        .bbs-list
        ul
        li
        .bbs-list-last-li
        .bbs-list-li-cont
        .list-cont-info {
        font-size: 14px;
    }
    .wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li {
        padding: 20px 0;
    }
    .wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-li .bbs-list-li-icon,
    .wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-last-li .bbs-list-li-icon {
        width: 25px;
        background-size: contain;
        margin-left: 5%;
    }
    .wrap .main .main-content .section05 .section-content .bbs-list ul li .bbs-list-last-li .bbs-list-li-icon:hover {
        width: 25px;
    }
    .wrap .main .main-content .section05 .section-content .bbs-btns .bbs-btn {
        font-size: 15px;
        padding: 6px 20px;
    }

    /* sub */
    .wrap .sub .sub-visual {
        height: 250px;
    }
    .wrap .sub .sub-visual .sub-visual-text {
        width: auto;
        font-size: 25px;
    }

    .wrap .sub .sub-content.recruit01,
    .wrap .sub .sub-content.recruit02,
    .wrap .sub .sub-content.recruit03 {
        margin-top: 40px;
    }
    .wrap .sub .sub-content.recruit01 .sub-content-cont,
    .wrap .sub .sub-content.recruit02 .sub-content-cont,
    .wrap .sub .sub-content.recruit03 .sub-content-cont {
        padding: 50px 0px;
    }
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
        font-size: 22px;
    }

    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-header .title {
        font-size: 20px;
    }
    .wrap .sub .sub-content.bbs-view .sub-content-cont .bbs-view-cont {
        padding: 20px 0;
    }
    .wrap .sub .sub-content.bbs-view .bbs-btns {
        margin: 0px;
    }
    .wrap .sub .sub-content.bbs-view .bbs-btns .bbs-btn {
        font-size: 15px;
        padding: 6px 20px;
    }

    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step {
        display: grid;
    }
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-arrow {
        display: block;
        padding: 20px 15px;
        text-align: center;
        transform: rotate(90deg);
    }

    /* footer */
    .wrap .footer .footer-wrap {
        width: auto;
        padding: 20px 10px;
        font-size: 13px;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont {
        flex-direction: column-reverse;
        text-align: center;
    }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-contact {
        margin-top: 50px;
    }

    .popup {
        width: 80%;
        padding: 30px 20px 15px !important;
    }
    .popup-content01 {
        width: 47%;
        margin-right: 2%;
    }
    .popup-content02 {
        width: 47%;
        margin-left: 2%;
    }
    .popup-content .popup-content-circle {
        width: 50px;
        height: 50px;
    }
    .popup-content-circle-title {
        font-size: 0.9em !important;
    }
}
@media all and (max-width: 450px) {
    /* main */
    .wrap .main .main-content .section01,
    .wrap .main .main-content .section02,
    .wrap .main .main-content .section03,
    .wrap .main .main-content .section04,
    .wrap .main .main-content .section06 {
        margin-top: 100px;
        padding: 0 10px;
    }
    .wrap .main .main-content .section05 {
        margin-top: 100px;
        padding: 0 20px;
    }
    .wrap .main .main-content .section02 .section-content .history .box ul li {
        padding: 0px;
        width: 65%;
    }
    .wrap .main .main-content .section03 .section-title .title-text p,
    .wrap .main .main-content .section04 .section-title .title-text p {
        width: 100%;
    }
    .wrap .main .main-content .section06 .section-content .section-content-boxs .boxs .boxs-logo img {
        width: 50%;
    }
    /* main */

    /* sub */
    .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-text {
        font-size: 20px;
    }
    /* 보도자료 */
    .wrap .sub .sub-title {
        font-size: 35px;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li .contents .content {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li {
        width: 100%;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:first-child {
        margin-top: 0px !important;
    }
    .wrap .sub .sub-content.bbs .sub-content-cont .bbs-list ul li:nth-child(n) {
        margin-left: 0px;
        margin-top: 50px;
    }
    /* sub */
}

/** add css **/
.year_fixed {
    position: fixed;
    top: 71px;
    height: 50px;
    z-index: 9990;
    background: rgb(255, 255, 255);
}
