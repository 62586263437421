.hero__scroll {
    position: absolute;
    bottom: 60px;
    width: 200px;
    margin: auto;
    display: block;
    cursor: pointer;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    text-transform: uppercase;
}

.hero__scroll .chevron {
    margin-top: 20px;
    display: block;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    color: #FF4081;
}

.chevron.bottom:before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    vertical-align: top;
    width: 20px;
}


.cursor-pointer {cursor: pointer;}

.main-year-box-fixed {position: fixed; top: 100px}


.question-modal-content {display: flex; flex-direction: column; align-items: center; padding: 20px;}
.question-modal-content img {height: 200px; width: auto;}
.question-modal-content .help-text {margin:10px; font-size: 26px; line-height: 26px; letter-spacing: -0.03em; color: rgb(67, 66, 66); padding-top: 10px;}
.question-modal-content .help-number {margin:10px; font-size: 30px; line-height: 40px; letter-spacing: -0.03em; color: #10CFC9; font-weight: 700;}
.question-modal-content .help-time {font-size: 15px; letter-spacing: -0.03em; color: rgb(152, 151, 151); line-height: 27px; text-align: center; width: 100%;}
.question-modal-content .help-kakao { display: inline-flex; appearance: none; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; user-select: none; position: relative; white-space: nowrap; vertical-align: middle; outline: transparent solid 2px; outline-offset: 2px; width: 190px; line-height: 1.2; border-radius: 25px; font-weight: 600; transition-duration: 200ms; height: 50px; min-width: 2.5rem; font-size: 15px; padding-inline: 15px; background: #10CFC9; color: rgb(255, 255, 255);  border: 0; border-color: #10CFC9; margin-top: 30px; cursor: pointer; }

@media all and (max-width:640px) {
    .question-modal-content img {height: 170px;}
}

.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs-custom{margin: 0px; padding: 0px; font-size: 0px; white-space: nowrap; overflow-x: auto; -ms-overflow-style: none; scrollbar-width: none;}
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs-custom::-webkit-scrollbar{display: none;}
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs-custom li{ 
    cursor: pointer;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #4A4D55;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 22px;
    gap: 10px;
    border: 1.4px solid #EAECF0;
    border-radius: 40px;
    flex: none;
    order: 2;
    flex-grow: 0;
    display:inline-block;
    margin-right:24px
 }
 
.wrap .sub .sub-content.recruit01 .sub-content-cont ul.tabs-custom li.current{ color: #2D529B; background-color: #E7EDFF; border: 0;  }




@media (max-width: 1024px){
    
    .footer-header {
        flex-direction: column;
    }
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon ul li {margin-top: 2rem}
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon ul li div {margin-left: 0 !important;}
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon ul {justify-content: space-around !important; display: flex;}  
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon .sns {margin-top: 20px !important}  
    
}

@media all and (max-width:640px) {
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon ul li div img { width: 90%; }
    .wrap .footer .footer-wrap .footer-header .footer-sns-icon ul li div img { width: 100%; }
    .wrap .footer .footer-wrap .footer-menu {display: flex; flex-direction: row; justify-content: inherit; border-bottom: 1px solid #fff;}
    .wrap .footer .footer-wrap .footer-header .footer-menu ul { margin-top: 15px !important;margin-bottom: 15px !important}
    .wrap .footer .footer-wrap .footer-header { border: 0}
    .wrap .footer .footer-wrap .footer-info {padding-top: 20px;}
    .wrap .footer .footer-wrap .footer-info .footer-info-cont {flex-direction: column; }
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-contact {text-align: left; margin-top: 20px;}
    .wrap .footer .footer-wrap .footer-info .footer-info-cont .footer-info-text {text-align: left}
    
}
